import React, { useState } from "react";
import moment from "moment-timezone";

import { useInterval } from "../../hooks/useInterval";

import {
  clockContainer,
  clockHours,
  clockMinutes,
  clockSeconds,
} from "./Clock.module.scss";

const getHandRotation = (num) => (360 * num) / 60 - 90;
const getHourHandRotation = (hour, minute) =>
  (360 * (hour % 12)) / 12 + 360 * (minute / 60) * (1 / 12) - 90;

export const Clock = ({ timezone }) => {
  const [timeConfig, setTimeConfig] = useState({ h: 0, m: 0, s: 0 });

  useInterval(() => {
    if (timezone) {
      const clockTimezone = moment().tz(timezone);
      setTimeConfig({
        h: Number(clockTimezone.format("H")),
        m: Number(clockTimezone.format("m")),
        s: Number(moment().format("s")),
      });
    }
  }, 1000);

  return (
    <div className={clockContainer}>
      <div
        className={clockHours}
        style={{
          transform: `rotate(${getHourHandRotation(
            timeConfig.h,
            timeConfig.m
          )}deg)`,
        }}
      ></div>
      <div
        className={clockMinutes}
        style={{
          transform: `rotate(${getHandRotation(timeConfig.m)}deg)`,
        }}
      ></div>
      <div
        className={clockSeconds}
        style={{
          transform: `rotate(${getHandRotation(timeConfig.s)}deg)`,
        }}
      ></div>
    </div>
  );
};
