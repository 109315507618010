import { graphql, navigate } from "gatsby";
import React from "react";
import parse from "html-react-parser";

import Layout from "../../../components/Layout";
import SubBanner from "../../../components/SubBanner/SubBanner";
import StandardBanner from "../../../components/StandardHero/StandardHero";
import { Button } from "../../../components/Button/Button";
import { Map } from "../../../components/Map/Map";

import {
  contactUsContainer,
  timeZonesContainer,
  timeZonesContacts,
  timeZoneName,
} from "./index.module.scss";
import { Clock } from "../../../components/Clock/Clock";

const ContactUs = ({
  data: {
    strapiContactUs: { hero, offices, phoneContacts, subBanner, metaData },
  },
}) => {
  const breadcrumbs = [{ name: "CONTACT US" }];

  return (
    <Layout
      seo={{
        title: metaData?.pageTitle,
        description: metaData?.metadescription,
        image: hero.image.localFile.publicURL,
      }}
    >
      <div className={contactUsContainer}>
        {hero ? (
          <StandardBanner
            image={hero.image.localFile.childImageSharp.gatsbyImageData}
            showBreadcrumb={hero.showBreadcrumb}
            breadcrumbs={breadcrumbs}
          />
        ) : null}
        {subBanner ? (
          <SubBanner heading={subBanner.heading} image={subBanner.image.url} />
        ) : null}
        <div className={timeZonesContainer}>
          {offices.length
            ? offices.map((office) => (
                <div key={office.location}>
                  <p className={timeZoneName}>{office.location}</p>
                  <p>{parse(office.address.replace("\n", "<br />"))}</p>

                  <Clock timezone={office.timezone} />
                </div>
              ))
            : null}
          <div className={timeZonesContacts}>
            {phoneContacts.map((contact) => (
              <p key={contact.name}>
                <small>{contact.name}:</small>
                <br />
                {contact.value}
              </p>
            ))}
            <p>
              <Button
                bkgColor="blackOnLight"
                label="Message us"
                handleOnClick={() => navigate("/about/get-in-touch")}
              ></Button>
            </p>
          </div>
        </div>
        <Map markers={offices} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    strapiContactUs {
      hero {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
        }
        showBreadcrumb
      }
      subBanner {
        image {
          url
        }
        heading
      }
      offices {
        address
        latitude
        location
        longitude
        mapMarkerPosition
        timezone
      }
      phoneContacts {
        name
        value
      }
      metaData {
        pageTitle
        metadescription
      }
    }
  }
`;

export default ContactUs;
