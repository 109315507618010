import React from "react";
import { Button } from "../Button/Button";
const styles = require("./SubBanner.module.scss");

const SubBanner = (props) => {
  const { heading, image, cta1, cta2 } = props;

  return (
    <>
      {cta1 || cta2 ? (
        <div className={styles.ctaBanner}>
          <div className={styles.titleContainer}>
            <img src={image} alt="logo" />
            <div className={styles.title}>
              <h2>{heading}</h2>
            </div>
          </div>
          <div className={styles.ctas}>
            {cta1 ? (
              <Button
                type={cta1.type || "internalLink"}
                bkgColor={cta1.bkgColor}
                label={cta1.cta.text}
                icon={cta1.icon}
                handleOnClick={cta1.handleOnClick || null}
                toPage={cta1.cta.link}
              ></Button>
            ) : null}
            {cta2 ? (
              <Button
                type={cta2.type || "internalLink"}
                bkgColor={cta2.bkgColor}
                label={cta2.cta.text}
                icon={cta2.icon}
                handleOnClick={cta2.handleOnClick || null}
                toPage={cta2.cta.link}
              ></Button>
            ) : null}
          </div>
        </div>
      ) : (
        <div className={styles.titleContainer}>
          <img src={image} alt="logo" />
          <div className={styles.title}>
            <h2>{heading}</h2>
          </div>
        </div>
      )}
    </>
  );
};

export default SubBanner;
