import React, { useCallback } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  OverlayView,
} from "@react-google-maps/api";

import {
  mapContainer,
  mapLabel,
  bottomLeft,
  bottomRight,
  topLeft,
  topRight,
} from "./Map.module.scss";
import mapStyles from "./mapStyles";

const markerPositions = { bottomLeft, bottomRight, topLeft, topRight };

export const Map = ({ markers }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
  });

  const onLoad = useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();

    // Add all markers to the bounds so we center on all the markers.
    markers?.map((mark) => {
      bounds.extend(
        new window.google.maps.LatLng(
          Number(mark.latitude),
          Number(mark.longitude)
        )
      );
    });

    map.fitBounds(bounds);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerClassName={mapContainer}
      zoom={2.5}
      onLoad={onLoad}
      options={{
        disableDefaultUI: true,
        gestureHandling: "none",
        styles: mapStyles,
        zoomControl: false,
      }}
    >
      {markers && markers.length > 0 ? (
        markers?.map((mark, i) => {
          const position = {
            lat: Number(mark.latitude),
            lng: Number(mark.longitude),
          };

          return (
            <React.Fragment key={`snazzy-marker-${i}`}>
              <Marker
                icon={{
                  anchor: new window.google.maps.Point(15, 15),
                  fillColor: "#2D2D2D",
                  fillOpacity: 1,
                  path: `M 7.5, 15 a 7.5,7.5 0 1,1 15,0 a 7.5,7.5 0 1,1 -15,0`,
                  scale: 1,
                  strokeWeight: 0,
                }}
                position={position}
              />
              <OverlayView mapPaneName="overlayMouseTarget" position={position}>
                <div
                  className={`${mapLabel} ${
                    markerPositions[mark.mapMarkerPosition]
                  }`}
                >
                  {mark.location}
                </div>
              </OverlayView>
            </React.Fragment>
          );
        })
      ) : (
        <></>
      )}
    </GoogleMap>
  ) : null;
};
